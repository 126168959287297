import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./styles/custom.scss";

import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import SedeDetalle from "./components/SedeDetalle";
import Sedes from "./components/Sedes";
import Nosotros from "./components/Nosotros";
import Corporativo from "./components/Corporativo";
import Contact from "./components/Contact";
import ModalContact from "./components/ModalContact";
import { HIDE_IN_PROD } from "./services/config";
import PromoToast from "./components/PromoToast";
import FreePass from "./components/FreePass";
import { Provider } from "react-redux";
import store from "./store";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ModalContact>
          <Navbar></Navbar>
          <main style={{ marginTop: "-5rem" }}>
            <Outlet />
            <PromoToast />
          </main>
          <Footer></Footer>
        </ModalContact>
      </>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "sedes",
        element: <Sedes />,
      },
      {
        path: "sedes/:id",
        element: <SedeDetalle />,
      },
      {
        path: "nosotros",
        element: <Nosotros />,
      },
      {
        path: "corporativo",
        element: <Corporativo />,
      },
      {
        path: "contacto",
        element: <Contact />,
      },
      {
        path: "free-pass",
        element: <FreePass />,
      },
      {
        path: "free-pass-qr-pardo",
        element: <FreePass />,
      },
    ],
  },
]);

function App() {
  document.documentElement.lang = "es";
  return (
    <>
      <Provider store={store}>
        <RouterProvider router={router}></RouterProvider>
      </Provider>
    </>
  );
}

export default App;

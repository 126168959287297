import { configureStore } from "@reduxjs/toolkit";
import b2Api from "./api";

const store = configureStore({
  reducer: {
    [b2Api.reducerPath]: b2Api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(b2Api.middleware),
});

export default store;

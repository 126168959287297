import { ReactComponent as BeBalance } from '../assets/BeBalance.svg';
import Slider from 'react-slick';
import { SEDES } from '../data/SEDES';
import { ScrollRestoration, useNavigate } from 'react-router-dom';
import { firebaseStorage } from '../services/firebase-b2-portal'
import { useContext, useEffect, useRef } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { ModalContactContext } from './ModalContact';
import { PROGRAMAS } from '../data/PROGRAMAS';
import { SERVICIOS, SERVICIOS_COMPLEMENTARIOS } from '../data/SERVICIOS';

const sedesSettings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const programsSettings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default function Home() {
    const navigate = useNavigate();
    const videoElem = useRef();
    const modalContact = useContext(ModalContactContext)

    useEffect(() => {
        let videoRef = ref(firebaseStorage, 'gs://b2-portal.appspot.com/landing/assets/b2_home_bg_reel_360.mp4')
        getDownloadURL(videoRef)
            .then((url) => {
                videoElem.current.innerHTML = `
                <video class="img-filter-bright-75" style="width: 100%; height: 100%; object-fit: cover; display: block;" loop muted playsinline autoplay preload="metadata">
                    <source src="${url}" type="video/mp4" />
                </video>
                `
            })
            .catch((error) => {
                // Handle any errors
            });
    }, [])

    return (
        <>
            <ScrollRestoration />
            <section style={{ position: "relative", paddingBottom: "8rem", paddingTop: "8rem" }}>

                <div className="d-flex flex-column align-items-center py-5 px-4 fs-1 animate-fade-in">
                    <BeBalance style={{ maxHeight: "8rem", width: "100%" }} className='text-white mb-3 filter-shadow-embossed'></BeBalance>
                    <div className='text-white text-center fst-italic text-shadow-embossed' style={{ fontSize: "1.1em", fontWeight: "500" }}>
                        CAMBIA TU <span className='text-primary'>MENTE</span>, CAMBIA TU <span className='text-primary'>CUERPO</span>
                    </div>
                </div>

                <div className='img-bg-container bg-dark' ref={videoElem}>
                </div>
            </section>

            <section style={{ position: "relative" }} className='py-5 px-3'>
                <div className='b2-container py-5'>
                    <div className='row animate-fade-in animate-delay-500'>
                        <div className='col-12 col-lg-6 d-flex align-items-center justify-content-center'>
                            <img style={{ maxWidth: "80vw", maxHeight: "480px", aspectRatio: 1, display: "block" }} alt='sedes'
                                src="/images/b2-bienvenidos.png" />
                        </div>
                        <div className='col-12 col-lg-6 fs-3 d-flex flex-column align-items-center justify-content-center'>
                            <div style={{ fontWeight: "500", maxWidth: "34rem" }}>
                                <p style={{ lineHeight: "140%" }} className='mb-3'>
                                    A una experiencia exclusiva.
                                    Disfruta de un sistema <span className='text-primary'>360°</span> de
                                    entrenamiento diseñado en el
                                    balance integral, físico y mental
                                    para elevar tu estilo de vida.
                                </p>
                                <p className='mb-4'>
                                    ¿Preparados para el siguiente nivel?
                                </p>
                                <button style={{ fontWeight: "600" }}
                                    className='btn btn-primary text-white fs-4 hover-scale py-3 px-4'
                                    onClick={() => modalContact.setShow(true)}>
                                    be balance
                                </button>
                            </div>
                            {/* <div style={{ textAlign: "center" }}>
                                <button style={{ fontSize: "1.25rem" }} className='navbar-button' onClick={() => modalContact.setShow(true)}>be balance</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>


            <section style={{ position: "relative" }} className='py-5 px-3'>
                <div className='b2-container py-5 my-5'>
                    <div className='text-white fw-bold fs-1 mb-3 text-shadow-embossed'>NUESTRAS SEDES</div>
                    <div className='text-white mb-4 fs-5 text-shadow-embossed'>Decide hoy, entrena y disfruta la experiencia be balance.</div>
                    <div className='px-4'>
                        <Slider {...sedesSettings}>
                            {SEDES.map(sede => {
                                return (
                                    <div id={sede.id} key={sede.id}>
                                        <div className='mx-2 mb-1 p-4 hover-scale' onClick={() => navigate("/sedes/" + sede.id, { preventScrollReset: false })}
                                            style={{ backgroundColor: "white", borderRadius: "1rem", height: "100%", cursor: "pointer", position: "relative" }}>
                                            <div className='fs-4 fw-bold mb-1'>{sede.name}</div>
                                            <div className='mb-1'><i className="bi bi-geo-alt-fill text-primary"></i> {sede.address}</div>
                                            <div className='mb-1'><i className="bi bi-whatsapp text-primary"></i> {sede.phone}</div>
                                            <div className='fs-5 fw-bold text-primary' style={{ position: "absolute", right: "1rem", bottom: "0.5rem" }}>
                                                <i className="bi bi-arrow-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
                <img className='img-bg-container img-filter-bright-50' alt='sedes'
                    src="/images/fotor_2023-4-16_8_9_59.jpg" />
            </section>

            <section className='bg-primary'>
                <div className='b2-container py-5'>
                    <h1 className='fw-bold text-center text-white mt-5 mb-5'>
                        SISTEMA BALANCE 360°
                    </h1>
                    <div className='px-4 mb-5'>
                        <Slider {...programsSettings}>
                            {PROGRAMAS.map(program => {
                                return (
                                    <div key={program.title}>
                                        <div className='mx-4 mb-1'
                                            style={{ backgroundColor: "white", height: "100%", position: "relative" }}>
                                            <img style={{ height: "18rem", width: "100%", objectFit: "cover", display: "block" }}
                                                alt="programa" src={program.imgsrc} />
                                            <div className='p-4'>
                                                <div className='fs-4 fw-bold mb-1'>{program.title}</div>
                                                <div>
                                                    {program.description}
                                                </div>
                                            </div>
                                            {/* <div className='fs-5 fw-bold text-primary' style={{ position: "absolute", right: "1rem", bottom: "0.5rem" }}>
                                                <i className="bi bi-arrow-right"></i>
                                            </div> */}
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
            </section>

            <section className='b2-container py-5'>
                <h1 className='fw-bold text-center mt-5 mb-5'>
                    NUESTROS SERVICIOS
                </h1>
                <div className='row gap-5 mb-5 p-4 justify-content-center'>
                    {
                        SERVICIOS.map(servicio => {
                            return (
                                <div className='col d-flex flex-column gap-4 align-items-center' style={{ minWidth: "16rem", maxWidth: "24rem" }}>
                                    <img className='rounded-circle' style={{ width: "100%", aspectRatio: "1", maxWidth: "10rem", maxHeight: "10rem", objectFit: "cover", display: "block" }}
                                        alt="servicio" src={servicio.imgsrc} />
                                    <div>
                                        <p className='fw-bold fs-5'>
                                            {servicio.title.toUpperCase()}
                                        </p>
                                        <p>
                                            {servicio.description}
                                        </p>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </section>

            <section className='b2-container py-5'>
                <h1 className='fw-bold text-center mb-5'>
                    SERVICIOS COMPLEMENTARIOS
                </h1>
                <div className='row gap-5 mb-5 p-4 justify-content-center'>
                    {
                        SERVICIOS_COMPLEMENTARIOS.map(servicio => {
                            return (
                                <div className='col d-flex flex-column gap-4 align-items-center' style={{ minWidth: "16rem", maxWidth: "24rem" }}>
                                    <img className='rounded-circle' style={{ width: "100%", aspectRatio: "1", maxWidth: "10rem", maxHeight: "10rem", objectFit: "cover", display: "block" }}
                                        alt="servicio" src={servicio.imgsrc} />
                                    <div>
                                        <p className='fw-bold fs-5'>
                                            {servicio.title.toUpperCase()}
                                        </p>
                                        <p>
                                            {servicio.description}
                                        </p>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </section>

        </>
    )
}
import './Footer.scss';
import { ReactComponent as LogoSimple } from '../assets/LogoSimple.svg';
import { Link } from 'react-router-dom';

export default function Footer() {

    return (
        <footer>
            <div className="footer-wrapper">
                <section className='footer-top'>
                    {/* <img className="footer-logo" src="b2logo_white.png" title='Logo' alt='Logo' /> */}
                    <LogoSimple className='footer-logo'></LogoSimple>

                    <span className="footer-social-links">
                        <a href="https://www.instagram.com/gimnasiob2" target='_blank' rel="noopener noreferrer"><i className="bi bi-instagram"></i></a>
                        <a href="https://www.tiktok.com/@gimnasiosb2" target='_blank' rel="noopener noreferrer"><i className="bi bi-tiktok"></i></a>
                        <a href="https://www.facebook.com/Gimnasiob2Peru" target='_blank' rel="noopener noreferrer"><i className="bi bi-facebook"></i></a>
                        <a href="https://wa.me/51938079688" target='_blank' rel="noopener noreferrer"><i className="bi bi-whatsapp"></i></a>
                        <a href="https://www.linkedin.com/company/gimnasiosb2" target='_blank' rel="noopener noreferrer"><i className="bi bi-linkedin"></i></a>
                    </span>
                </section>
                <hr className="footer-divider" />
                <section className="footer-columns">
                    <section>
                        <h4>ACERCA DE</h4>
                        <ul>
                            <li>
                                <Link to="nosotros">NOSOTROS</Link>
                            </li>
                            <li>
                                <Link to="sedes">SEDES</Link>
                            </li>
                            <li>
                                <Link to="contacto">FREE PASS</Link>
                            </li>
                        </ul>
                    </section>
                    <section>
                        <h4>CONTACTOS</h4>
                        <ul>
                            <li>
                                <a href="mailto:atencion@b2.pe">ATENCIÓN AL CLIENTE</a>
                            </li>
                            <li>
                                <a href="mailto:marketing@b2.pe">MARKETING</a>
                            </li>
                            <li>
                                <a href="mailto:alianzasyconvenios@b2.pe">PUBLICIDAD</a>
                            </li>
                            <li>
                                <a href="mailto:convocatorias@b2.pe">CONVOCATORIAS</a>
                            </li>
                            <li>
                                <Link to="corporativo">CORPORATIVOS</Link>
                            </li>
                        </ul>
                    </section>
                    <section>
                        <h4>LEGAL</h4>
                        <ul>
                            <li>
                                <Link to="/">POLÍTICA DE PRIVACIDAD</Link>
                            </li>
                            {/* <li>
                                <Link to="/">Condiciones de membresía</Link>
                            </li>
                            <li>
                                <Link to="/">Propiedad intelectual</Link>
                            </li> */}
                        </ul>
                    </section>
                </section>
                <hr className="footer-divider" />
                <section className="footer-bottom">
                    © {1900 + (new Date().getYear())} Gimnasio b2. All Rights Reserved.
                </section>
            </div>
        </footer>
    )

}
import { useLocation } from "react-router-dom";

export default function PromoToast() {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/" &&
        <div
          className="promo-toast rounded p-3 filter-shadow-embossed tw-overflow-auto"
          style={{ textWrap: "nowrap" }}
        >
          <div className="tw-flex tw-gap-3 tw-items-center">
            <div className="tw-flex tw-flex-col">
              <span className="tw-font-extrabold tw-text-2xl">PREVENTA</span>
              <span className="tw-font-extrabold tw-text-2xl">MERCADERES</span>
              <span className="tw-font-semibold">NUEVA SEDE PLUS</span>
              <div className="tw-h-px tw-w-full tw-bg-black tw-mb-1"></div>
              <span><i className="bi bi-geo-alt-fill"></i> AV. MERCADERES 279</span>
            </div>
            <div className="tw-h-full">
              <a
                href="https://api.whatsapp.com/send?phone=51938079688&text=¡Hola! Deseo información sobre la preventa de la Sede Mercaderes"
                target="_blank" rel="noreferrer"
                className="tw-bg-black tw-text-white tw-font-bold tw-text-lg tw-py-2 tw-px-3 tw-rounded-lg tw-transition tw-duration-300 tw-ease-in-out tw-transform tw-hover:tw-opacity-80"
              >
                INFO AQUÍ
              </a>
            </div>
          </div>
        </div>
      }
    </>
  );
}

import './Navbar.scss';
import { ReactComponent as Logo } from '../assets/Logo.svg';
import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function NavbarMenuItems() {
    return (
        <>
            <Link to="nosotros">be balance</Link>
            <Link to="sedes">Sedes</Link>
            <Link to="corporativo">Corporativos</Link>
            <Link to="contacto">Contacto</Link>
            <Link to="free-pass">FREE PASS</Link>
        </>
    )
}

export default function Navbar() {
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    useEffect(() => {
        setOpen(false)
    }, [location]);

    return (
        <>
            {open &&
                <div className='navbar-mobile-menu-container navbar-mobile-show fade-in-animate'>
                    <div style={{ textAlign: 'right' }} >
                        <span className='navbar-mobile-menu-container-toggle navbar-mobile-show'

                            onClick={() => setOpen(!open)}>
                            <i className='bi bi-x-lg' />
                        </span>
                    </div>

                    <div className='navbar-mobile-menu-links'>
                        <NavbarMenuItems />

                        {/* <button style={{ fontSize: "1.25rem" }} className='navbar-button'>INICIAR SESIÓN</button> */}
                    </div>

                </div>
            }
            <nav className={"navbar-container " + (scroll ? 'navbar-scrolled' : '')}>
                <Logo className='navbar-logo filter-shadow-embossed' onClick={() => navigate("/", { preventScrollReset: false })}></Logo>

                <div className='navbar-menu navbar-mobile-hide'>
                    <NavbarMenuItems />
                </div>

                <button className='navbar-button navbar-mobile-hide opacity-0' style={{ cursor: "default" }}>INICIAR SESIÓN</button>
                <span className='navbar-mobile-menu-container-toggle navbar-mobile-show'
                    onClick={() => setOpen(!open)}>
                    <i className='bi bi-list' />
                </span>
            </nav>

        </>
    )
}
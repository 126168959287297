import { ScrollRestoration } from "react-router-dom";
import ContactFormWsp from "./ContactFormWsp";



export default function Contact() {
    return (
        <>
            <ScrollRestoration />
            <section style={{ position: "relative", paddingTop: "5rem" }}>
                <div className="mt-5 py-5 text-white text-center">
                    <div className="fs-1 fw-bold text-shadow-embossed">CONTACTO</div>
                </div>
                <img className="img-bg-container img-filter-bright-50" alt="header"
                    src="../sven-mieke-tc1eFr68CTU-unsplash.jpg" />
            </section>

            <section className="d-flex flex-column align-items-center justify-content-center py-5">
                <h3>Contáctanos por WhatsApp</h3>
                <div className="p-3" style={{ width: "min(600px, 100%)" }}>
                    <ContactFormWsp></ContactFormWsp>
                </div>
            </section>

            <section className="d-flex flex-column align-items-center justify-content-center py-5">
                <h3 className="mb-4">o contacta una sección</h3>
                <p>
                    ATENCIÓN AL CLIENTE: <a href="mailto:atencion@b2.pe">atencion@b2.pe</a>
                </p>
                <p>
                    MARKETING: <a href="mailto:marketing@b2.pe">marketing@b2.pe</a>
                </p>
                <p>
                    PUBLICIDAD: <a href="mailto:alianzasyconvenios@b2.pe">alianzasyconvenios@b2.pe</a>
                </p>
                <p>
                    CONVOCATORIAS: <a href="mailto:convocatorias@b2.pe">convocatorias@b2.pe</a>
                </p>
                <p>
                    CORPORATIVOS: <a href="mailto:corporativos@b2.pe">corporativos@b2.pe</a>
                </p>
            </section>
        </>
    )
}
import { ScrollRestoration, useLocation } from "react-router-dom";
import FreePassForm from "./FreePassForm";

export default function FreePass() {
  const location = useLocation();

  return (
    <>
      <ScrollRestoration />

      <section className="tw-min-h-[18rem] tw-w-full tw-bg-black tw-relative">
        <div className="tw-absolute tw-bottom-12 tw-w-full tw-text-center tw-text-white tw-font-bold tw-text-4xl text-shadow-embossed">
          {location.pathname === "/free-pass-qr-pardo" ? "3 FREE DAYS - SEDE PARDO" : "FREE PASS"}
        </div>
      </section>

      <section className="tw-py-8 tw-flex tw-flex-col tw-items-center px-2">
        <div style={{ width: "min(600px, 100%)" }}>
          <FreePassForm variant={location.pathname} />
        </div>
      </section>
    </>
  );
}

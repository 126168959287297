import { ScrollRestoration } from "react-router-dom";
import { ReactComponent as Icono1 } from '../assets/Corp_Icono_1.svg';
import { ReactComponent as Icono2 } from '../assets/Corp_Icono_2.svg';
import { ReactComponent as Icono3 } from '../assets/Corp_Icono_3.svg';
import { ReactComponent as Icono4 } from '../assets/Corp_Icono_4.svg';

export default function Corporativo() {
    return (
        <>
            <ScrollRestoration />
            <section style={{ position: "relative", paddingTop: "5rem" }}>
                <div className="mt-5 py-5 text-white text-center">
                    <div className="fs-1 fw-bold text-shadow-embossed">CORPORATIVOS</div>
                </div>
                <img className="img-bg-container img-filter-bright-50" alt="header"
                    src="/images/CORPORATIVO_COVER_720.jpg" />
            </section>

            <section style={{ position: "relative" }} className='px-3 animate-fade-in'>
                <div className='container py-5 fs-5 text-center'>
                    <h2 className="mb-3">
                        TU EQUIPO FUERTE Y <span className="text-primary">SALUDABLE</span>
                    </h2>
                    <p>
                        Nuestro servicio corporativo es más que un beneficio; es una
                        estrategia para el éxito a largo plazo. Transforma tu empresa, crea equipos más saludables y
                        alcanza un rendimiento óptimo con nuestros programas diseñados para ayudar a construir
                        equipos más saludables, felices y productivos dentro y fuera del trabajo.
                    </p>
                    <p>
                        Escríbenos a <a href="mailto:corporativos@b2.pe">corporativos@b2.pe</a>
                    </p>
                </div>

            </section>


            <section className='b2-container px-3 animate-fade-in d-flex flex-column align-items-center'>
                <div className='row mb-5 p-4 justify-content-center'>

                    <div className='col d-flex flex-column gap-4 align-items-center' style={{ minWidth: "16rem", maxWidth: "24rem" }}>
                        <Icono1 style={{ width: "100%", aspectRatio: "1", maxWidth: "10rem", maxHeight: "10rem" }}></Icono1>
                        <div>
                            <p className='fw-bold fs-5'>
                                TARIFAS EXCLUSIVAS
                            </p>
                            <p>
                                Los precios más bajos a nivel cadena según cate goría
                            </p>
                        </div>
                    </div>

                    <div className='col d-flex flex-column gap-4 align-items-center' style={{ minWidth: "16rem", maxWidth: "24rem" }}>
                        <Icono2 style={{ width: "100%", aspectRatio: "1", maxWidth: "10rem", maxHeight: "10rem" }}></Icono2>
                        <div>
                            <p className='fw-bold fs-5'>
                                BENEFICIOS EXTENSIVOS
                            </p>
                            <p>
                                Todos los beneficios se comparten con los familiares directos
                            </p>
                        </div>
                    </div>

                    <div className='col d-flex flex-column gap-4 align-items-center' style={{ minWidth: "16rem", maxWidth: "24rem" }}>
                        <Icono3 style={{ width: "100%", aspectRatio: "1", maxWidth: "10rem", maxHeight: "10rem" }}></Icono3>
                        <div>
                            <p className='fw-bold fs-5'>
                                BIENESTAR LABORAL
                            </p>
                            <p>
                                Salario emocional 100% asegurado
                            </p>
                        </div>
                    </div>

                    <div className='col d-flex flex-column gap-4 align-items-center' style={{ minWidth: "16rem", maxWidth: "24rem" }}>
                        <Icono4 style={{ width: "100%", aspectRatio: "1", maxWidth: "10rem", maxHeight: "10rem" }}></Icono4>
                        <div>
                            <p className='fw-bold fs-5'>
                                MAYOR PRODUCTIVIDAD
                            </p>
                            <p>
                                Equipo más activo y enfocado en sus actividades diarias
                            </p>
                        </div>
                    </div>

                </div>
                <div className='row gap-5 mb-5 p-4 justify-content-center align-items-center'>
                    <div className='col d-flex flex-column gap-4 align-items-center' style={{ minWidth: "16rem", maxWidth: "32rem" }}>
                        {/* <Icono4 style={{ width: "100%", aspectRatio: "1", maxWidth: "10rem", maxHeight: "10rem" }}></Icono4> */}
                        <div>
                            <p className='fw-bold fs-5'>
                                PAUSAS <span className="text-primary">ACTIVAS</span>
                            </p>
                            <p>
                                Diseñadas para incrementar la energía y productividad en la jornada laboral. Sesiones cortas de actividad física y estiramientos que despiertan la vitalidad, reducen el estrés y fomentan la concentración.
                            </p>
                        </div>
                    </div>
                    <div className="col">
                        <img style={{ width: "100%", objectFit: "cover", display: "block", maxWidth: "48rem", minWidth: "16rem" }} alt="pausas activas"
                            src="/images/FOTO-PAUSAS-ACTIVAS_1080.jpg" />
                    </div>
                </div>
            </section>

            <section className='b2-container py-5'>
                <h1 className='fw-bold text-center mb-5'>
                    NUESTROS <span className="text-primary">PARTNERS</span>
                </h1>
                <img style={{ width: "100%", objectFit: "cover", display: "block" }} alt="partners"
                    src="/images/NUESTROS-PARTNERS.png" />

            </section>

            <section className='b2-container pb-5'>
                <div className='row justify-content-center'>
                    <a style={{ fontWeight: "600" }}
                        href="mailto:corporativos@b2.pe"
                        className='col-auto btn btn-primary text-white fs-4 hover-scale py-3 px-4'>
                        CONTÁCTANOS
                    </a>
                </div>

            </section>

        </>
    )
}
import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAypsX4v1hFCI1bDBtx9XMAwFX74FYYC5U",
  authDomain: "b2-portal.firebaseapp.com",
  projectId: "b2-portal",
  storageBucket: "b2-portal.appspot.com",
  messagingSenderId: "445342517014",
  appId: "1:445342517014:web:0488abbcc510b324b1f237"
};

export const firebaseApp = initializeApp(firebaseConfig)

export const firebaseStorage = getStorage(firebaseApp)
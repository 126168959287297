import { ScrollRestoration } from "react-router-dom";
import { ReactComponent as BeBalance } from '../assets/BeBalance.svg';



export default function Nosotros() {
    return (
        <>
            <ScrollRestoration />
            <section style={{ position: "relative", paddingTop: "5rem" }}>
                <div className="mt-5 py-5 text-white text-center">
                    <div className="fs-1 fw-bold text-shadow-embossed">NOSOTROS</div>
                </div>
                <img className="img-bg-container img-filter-bright-75" alt="header"
                    src="/images/Nosotros.jpg" />
            </section>

            <section style={{ position: "relative" }} className='py-5 px-3'>
                <div className='b2-container py-5'>
                    <div className='row animate-fade-in animate-delay-500'>
                        <div className='col-12 col-lg-6 d-flex flex-column align-items-center justify-content-center fs-1 mb-5'
                            style={{ color: "#616161" }}>
                            <p>
                                <span className="fw-bold" style={{ fontSize: "1.8em" }}>be <span className='text-primary'>balance</span></span><br />
                                CAMBIA TU <span className='text-primary fst-italic'>MENTE,</span><br />
                                CAMBIA TU <span className='text-primary fst-italic'>CUERPO</span>
                            </p>
                        </div>
                        <div className='col-12 col-lg-6 fs-4     d-flex flex-column align-items-center justify-content-center'>
                            <div style={{ fontWeight: "500", maxWidth: "40rem" }}>
                                <p style={{ lineHeight: "140%" }} className='mb-3'>
                                    Somos la Cadena Peruana de Gimnasios líderes en el rubro de salud y bienestar, y enfocados en brindarte una experiencia integral de entrenamiento para alcanzar el balance físico, emocional y mental necesario para llevar una vida plena y saludable.
                                </p>
                                <p style={{ lineHeight: "140%" }} className='mb-3'>
                                    Te guiamos y acompañamos en cada etapa de este viaje hacia tu bienestar total.
                                </p>
                                <p style={{ lineHeight: "140%" }} className='mb-3'>
                                    ¡Descubre tu mejor versión!
                                </p>
                                <p style={{ lineHeight: "140%" }} className='mb-3'>
                                    Juntos lo lograremos
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
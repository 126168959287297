import { Modal } from "react-bootstrap";
import ContactFormWsp from "./ContactFormWsp";
import { createContext, useState } from "react";

export const ModalContactContext = createContext(null)

export default function ModalContact({ children }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <ModalContactContext.Provider value={{
                show: show, 
                setShow: setShow
            }}>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Haz tu consulta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ContactFormWsp />
                    </Modal.Body>
                </Modal>

                <div className='b2-floating-whatsapp hover-scale' onClick={handleShow}>
                    <i className="bi bi-whatsapp"></i>
                </div>

                {children}
            </ModalContactContext.Provider>
        </>
    )
}
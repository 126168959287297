export const PROGRAMAS = [
    {
        title: "SHAPER",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FSHAPER_480.jpg?alt=media",
        description: "Mejora tu masa muscular y reduce el porcentaje de grasa y medidas corporales a través de un entrenamiento híbrido que combina la fuerza funcional con la resistencia."
    },
    {
        title: "HYPER",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FGROWTH_480.jpg?alt=media",
        description: "Diseñado para el desarrollo de la fuerza e hipertrofia muscular de manera simétrica con un entrenamiento de periodización según el perfil del socio."
    },
    {
        title: "PERFORMANCE",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FPERFORMANCE_480.jpg?alt=media",
        description: "Diseñado para que lo des todo en cada sesión de entrenamiento de corta duración. Programa de alta intensidad que incrementa el déficit de oxígeno acelerando tu metabolismo."
    },
    {
        title: "CROSSMAX",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FCROSSMAX_480.jpg?alt=media",
        description: "Lleva tu fuerza, potencia y resistencia al límite. Mejora tus capacidades físicas mientras incrementas y mejoras notablemente tu masa muscular y reduces el % de grasa."
    },
    {
        title: "RECOVERY",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FRECOVERY_480.jpg?alt=media",
        description: "Diseñado para reconectarte con la actividad física. Recupera tus capacidades de movilidad, rehabilitando y fortaleciendo tendones y articulaciones con la masa muscular."
    },
];

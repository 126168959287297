import { useForm } from "react-hook-form";
import { SEDES } from "../data/SEDES";
import api from "../store/api";
import { useEffect } from "react";

export default function FreePassForm({ variant }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    const minFecVal = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0];
    setValue("fecini", minFecVal);
  }, []);

  // const [isSent, setIsSent] = useState(false);

  const [putProspectoFreePass, { isLoading, isSuccess, isError, error }] =
    api.endpoints.putProspectoFreePass.useMutation();

  const onSubmit = async (data) => {
    console.log(data);
    let nota = `1. ¿Primera vez que visitas b2? ${data.primeravez}\n2. ¿Actualmente estás entrenado? ${data.entrenando}\n3. ¿Cuáles son las razones más importantes que te motivan a entrenar? ${data.motivo}`;

    putProspectoFreePass({
      codloc: ((variant === "/free-pass-qr-pardo") ? "09" : data.sede),
      nrodoc: data.dni,
      apepaterno: data.surname,
      nombres: data.name,
      email: data.email,
      telefono: data.phone,
      proid: ((variant === "/free-pass-qr-pardo") ? 136 : 134),
      fecini: data.fecini,
      fecnacimiento: data.fecnacimiento,
      sexo: data.sexo,
      nota: nota,
    });
  };

  return (
    <>
      {isError && (
        <div className="alert alert-danger">Ha ocurrido un error</div>
      )}
      {isError && error.data === "Ya tienes un Free Pass activo" &&
        <div className="alert alert-danger text-center">
          <p>¡Gracias por tu interés en nuestro Free Pass!</p>
          <p>Tu documento de identidad ya se encuentra registrado en nuestro
            sistema. Lamentablemente, debido a tu visita previa o membresía en
            los últimos 6 meses, no puedes acceder a este beneficio por el
            momento.</p>
          <p>Te invitamos a conocer nuestros planes promocionales vigentes. Tenemos uno perfecto para ti.</p>
          <p>¡Te esperamos para seguir entrenando juntos!</p>
          El equipo de Gimnasios b2
        </div>
      }
      {isSuccess ? (
        <div className="tw-py-24 tw-flex tw-flex-col tw-gap-4">
          <i className="bi bi-check-circle-fill text-success tw-text-[8rem] tw-text-center"></i>
          <p>
            Gracias, <strong>{getValues()?.name || ""}</strong>. Estás a un paso
            de disfrutar la <span className="text-primary">experiencia b2</span>
            . Pronto recibirás en tu correo el código de tu FREE PASS. Revisa
            también la bandeja de spam.
          </p>
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              (variant !== "/free-pass-qr-pardo") &&
              <div className="mb-3 input-group has-validation">
                <div className={"form-floating" + (errors.sede ? " is-invalid" : "")}>
                  <select
                    className={"form-select" + (errors.sede ? " is-invalid" : "")}
                    id="sedeSelect"
                    {...register("sede", { required: true })}
                  >
                    <option value="">Selecciona una sede</option>
                    {SEDES.filter((sede) => (!["san-borja", "mercaderes"].includes(sede?.id))).map((sede) => {
                      return <option key={sede.codloc} value={sede.codloc}>{sede.name}</option>;
                    })}
                  </select>
                  <label htmlFor="sedeSelect">Selecciona una sede</label>
                </div>
                <span className="invalid-feedback">
                  Este campo es obligatorio
                </span>
              </div>

            }

            <div className="mb-3 input-group has-validation">
              <div
                className={"form-floating" + (errors.name ? " is-invalid" : "")}
              >
                <input
                  id="nameInput"
                  placeholder="Nombre"
                  className={
                    "form-control" + (errors.name ? " is-invalid" : "")
                  }
                  {...register("name", { required: true })}
                />
                <label htmlFor="nameInput">Nombres</label>
              </div>
              <span className="invalid-feedback">
                Este campo es obligatorio
              </span>
            </div>

            <div className="mb-3 input-group has-validation">
              <div
                className={
                  "form-floating" + (errors.surname ? " is-invalid" : "")
                }
              >
                <input
                  id="surnameInput"
                  placeholder="Nombre"
                  className={
                    "form-control" + (errors.surname ? " is-invalid" : "")
                  }
                  {...register("surname", { required: true })}
                />
                <label htmlFor="surnameInput">Apellidos</label>
              </div>
              <span className="invalid-feedback">
                Este campo es obligatorio
              </span>
            </div>

            <div className="mb-3 input-group has-validation">
              <div
                className={"form-floating" + (errors.dni ? " is-invalid" : "")}
              >
                <input
                  id="dniInput"
                  placeholder="DNI"
                  className={"form-control" + (errors.dni ? " is-invalid" : "")}
                  {...register("dni", { required: true })}
                />
                <label htmlFor="dniInput">DNI</label>
              </div>
              <span className="invalid-feedback">
                Este campo es obligatorio
              </span>
            </div>

            <div className="mb-3 input-group has-validation">
              <div className={"form-floating" + (errors.sexo ? " is-invalid" : "")}>
                <select
                  className={"form-select" + (errors.sexo ? " is-invalid" : "")}
                  id="sexoSelect"
                  {...register("sexo", { required: true })}
                >
                  <option value="">Selecciona un sexo</option>
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                </select>
                <label htmlFor="sexoSelect">Sexo</label>
              </div>
              <span className="invalid-feedback">
                Este campo es obligatorio
              </span>
            </div>

            <div className="mb-3 input-group has-validation">
              <div className={"tw-w-full" + (errors.fecnacimiento ? " is-invalid" : "")}>
                <label htmlFor="fecnacimientoInput">Fecha nacimiento</label>
                <input
                  id="fecnacimientoInput"
                  type="date"
                  max={new Date(new Date().setDate(new Date().getDate())).toISOString().split("T")[0]}
                  className={"form-control" + (errors.fecnacimiento ? " is-invalid" : "")}
                  {...register("fecnacimiento", { required: true })} />
                <span className="invalid-feedback">
                  Este campo es obligatorio
                </span>
              </div>
            </div>

            <div className="mb-3 input-group has-validation">
              <div
                className={
                  "form-floating" + (errors.email ? " is-invalid" : "")
                }
              >
                <input
                  id="emailInput"
                  placeholder="Email"
                  className={
                    "form-control" + (errors.email ? " is-invalid" : "")
                  }
                  {...register("email", { required: true })}
                />
                <label htmlFor="emailInput">Email</label>
                <span className="invalid-feedback">
                  Este campo es obligatorio
                </span>
              </div>
            </div>

            <div className="mb-3 input-group has-validation">
              <div
                className={
                  "form-floating" + (errors.phone ? " is-invalid" : "")
                }
              >
                <input
                  id="phoneInput"
                  placeholder="Teléfono"
                  className={
                    "form-control" + (errors.phone ? " is-invalid" : "")
                  }
                  {...register("phone", { required: true })}
                />
                <label htmlFor="phoneInput">Teléfono</label>
                <span className="invalid-feedback">
                  Este campo es obligatorio
                </span>
              </div>
            </div>

            <div className="mb-3 input-group has-validation">
              <div className={"tw-w-full" + (errors.fecini ? " is-invalid" : "")}>
                <label htmlFor="feciniInput">Fecha inicio del FREE PASS</label>
                <input
                  id="feciniInput"
                  type="date"
                  min={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0]}
                  max={new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split("T")[0]}
                  className={"form-control" + (errors.fecini ? " is-invalid" : "")}
                  {...register("fecini", { required: true })} />
                <span className="invalid-feedback">
                  Este campo es obligatorio
                </span>
              </div>
            </div>

            <div className="border tw-rounded-md tw-border-neutral-800 tw-p-4 tw-mb-4">
              <div className="tw-mb-3">Encuesta:</div>

              <div className="mb-3 input-group has-validation">
                <div className={"form-floating" + (errors.primeravez ? " is-invalid" : "")}>
                  <select
                    className={"form-select" + (errors.primeravez ? " is-invalid" : "")}
                    id="primeravezSelect"
                    {...register("primeravez", { required: true })}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="SI">Si</option>
                    <option value="NO">No</option>
                  </select>
                  <label htmlFor="primeravezSelect">¿Primera vez que visitas b2?</label>
                </div>
                <span className="invalid-feedback">
                  Este campo es obligatorio
                </span>
              </div>

              <div className="mb-3 input-group has-validation">
                <div className={"form-floating" + (errors.entrenando ? " is-invalid" : "")}>
                  <select
                    className={"form-select" + (errors.entrenando ? " is-invalid" : "")}
                    id="entrenandoSelect"
                    {...register("entrenando", { required: true, maxLength: 80 })}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="SI">Si</option>
                    <option value="NO">No</option>
                  </select>
                  <label htmlFor="entrenandoSelect">¿Actualmente estás entrenado?</label>
                </div>
                <span className="invalid-feedback">
                  Este campo es obligatorio
                </span>
              </div>

              <div >¿Cuáles son las razones más importantes que te motivan a entrenar? <span className="tw-text-xs">Max 80 carácteres</span></div>
              <div className="mb-3 input-group has-validation">
                <div
                  className={
                    "form-floating" + (errors.motivo ? " is-invalid" : "")
                  }
                >
                  <textarea
                    id="motivoInput"
                    placeholder="Motivos"
                    className={
                      "form-control" + (errors.motivo ? " is-invalid" : "")
                    }
                    {...register("motivo", { required: true })}
                  />
                  <label htmlFor="motivoInput">Motivos: Salud, energía, etc</label>
                </div>
                <span className="invalid-feedback">
                  Este campo es obligatorio y debe ser máximo de 80 carácteres
                </span>
              </div>

            </div>

            <button
              type="submit"
              className="btn btn-primary text-white w-100"
              disabled={isLoading}
            >
              Enviar
            </button>
          </form>
        </>
      )}
    </>
  );
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URI } from "../enviroment";

const b2Api = createApi({
  reducerPath: "b2Api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URI,
  }),
  endpoints: (builder) => ({
    putProspectoFreePass: builder.mutation({
      query: (body) => {
        return {
          url: `landing/prospectos`,
          method: "POST",
          body: body,
        };
      },
    }),
  }),
});

export default b2Api;

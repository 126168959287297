import { useForm } from "react-hook-form";
import { SEDES } from "../data/SEDES";
import { useEffect } from "react";

const TIPO_CONSULTA = [
  // {
  //     tipo: "free_pass",
  //     title: "Free Pass"
  // },
  {
    tipo: "informes",
    title: "Informes",
  },
];

export default function ContactFormWsp({ tipoConsulta }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (tipoConsulta) {
      setValue("tipoConsulta", tipoConsulta);
    }
  }, [setValue, tipoConsulta]);

  const onSubmit = async (data) => {
    // console.log(data)

    let msg = `Hola, Gimnasio b2. Mi nombre es ${data.name} ${data.surname} con DNI ${data.dni} `;

    if (data.tipoConsulta === "free_pass") {
      msg += `estoy interesado en tener un FREE PASS para `;
    } else {
      msg += `quiero información de beneficios y tarifas de `;
    }

    msg += `la sede ${data.sede}`;

    window.open(
      "https://api.whatsapp.com/send?phone=51938079688&text=" + msg,
      "_blank"
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3 input-group has-validation">
          <div className={"form-floating" + (errors.name ? " is-invalid" : "")}>
            <input
              id="nameInput"
              placeholder="Nombre"
              className={"form-control" + (errors.name ? " is-invalid" : "")}
              {...register("name", { required: true })}
            />
            <label htmlFor="nameInput">Nombres</label>
          </div>
          <span className="invalid-feedback">Este campo es obligatorio</span>
        </div>

        <div className="mb-3 input-group has-validation">
          <div
            className={"form-floating" + (errors.surname ? " is-invalid" : "")}
          >
            <input
              id="surnameInput"
              placeholder="Nombre"
              className={"form-control" + (errors.surname ? " is-invalid" : "")}
              {...register("surname", { required: true })}
            />
            <label htmlFor="surnameInput">Apellidos</label>
          </div>
          <span className="invalid-feedback">Este campo es obligatorio</span>
        </div>

        <div className="mb-3 input-group has-validation">
          <div className={"form-floating" + (errors.dni ? " is-invalid" : "")}>
            <input
              id="dniInput"
              placeholder="DNI"
              className={"form-control" + (errors.dni ? " is-invalid" : "")}
              {...register("dni", { required: true })}
            />
            <label htmlFor="dniInput">DNI</label>
          </div>
          <span className="invalid-feedback">Este campo es obligatorio</span>
        </div>

        <div className="mb-3 form-floating">
          <select
            className="form-select"
            id="sedeSelect"
            {...register("sede", { required: true })}
          >
            {SEDES.filter((sede) => sede?.id !== "uni").map((sede) => {
              return <option value={sede.name}>{sede.name}</option>;
            })}
          </select>
          <label for="sedeSelect">Selecciona una sede</label>
        </div>

        <div className="mb-3 form-floating d-none">
          <select
            className="form-select"
            id="consultaSelect"
            {...register("tipoConsulta", { required: true })}
          >
            {TIPO_CONSULTA.map((tipoC) => {
              return <option value={tipoC.tipo}>{tipoC.title}</option>;
            })}
          </select>
          <label for="consultaSelect">Selecciona un tipo de consulta</label>
        </div>

        <button className="btn btn-primary text-white w-100" type="submit">
          <i className="bi bi-whatsapp"></i> Consultar
        </button>
      </form>
    </>
  );
}

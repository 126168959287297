export const SERVICIOS = [
    {
        title: "Plan de entrenamiento",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FPLAN_DE_ENTRENAMIENTO_360.jpeg?alt=media",
        description: "Tu mejor versión es nuestro objetivo. Disfruta entrenando con el sistema integral Balance 360°, desbloqueando tu máximo potencial y guiándote al siguiente nivel, alcanzando el éxito a través de los servicios y salas de entrenamiento que tenemos a tu disposición."
    },
    {
        title: "Clases grupales",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FCLASES_GRUPALES_360.jpg?alt=media",
        description: "Si buscas diversión, relajación, equilibrio o energía, nuestras clases grupales es la respuesta. Un amplio horario de multidisciplinas fitness y wellness esperan por ti en cada una de nuestras salas especialmente equipadas para ti. Únete a nosotros en esta emocionante experiencia."
    },
    {
        title: "Consultorio nutricional",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FEVALUACION_NUTRICIONAL_360.jpg?alt=media",
        description: "El 80% de tus resultados dependen de tu alimentación. Una asesoría nutricional adecuada es esencial para alcanzar tus objetivos de salud y bienestar. Nuestro staff profesional te proporciona asesoría personalizada adaptada a tus necesidades."
    },
    {
        title: "Sauna",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FSPA-360.jpg?alt=media",
        description: "Disfruta de nuestra relajante zona de sauna de cámara seca. El complemento perfecto para tus sesiones de entrenamiento. Acceso exclusivo para socios a un espacio para relajarte y recargar energías."
    },
    {
        title: "Estacionamiento",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FESTACIONAMIENTO_360.jpg?alt=media",
        description: "Facilitamos tu visita al gimnasio, brindándote comodidad desde el momento en el que llegas. Nuestras sedes cuentan con el servicio de estacionamiento gratuito durante un tiempo establecido para cada socio."
    },
];

export const SERVICIOS_COMPLEMENTARIOS = [
    {
        title: "Personal trainer",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FPERSONAL_TRAINERS_360.jpg?alt=media",
        description: "Creemos en hacer que tus metas se conviertan en realidad ¡y lo hacemos a tu ritmo! Nuestro servicio es el pasaporte hacía el éxito en corto tiempo. Alcanza tu objetivo respaldado por un profesional dedicado, trabajando a tu horario y comprometido contigo de principio a fin."
    },
    {
        title: "Tiendas boutique",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FTIENDA_ORGANICA_360.jpg?alt=media",
        description: "Hacemos que cada visita sea una experiencia única. Nuestras unidades de negocios complementan tu bienestar, y están diseñadas para acompañarte en tu aventura fitness: Organic & Fit, boutique deportiva, spa, barbería, cafetería, suplementación deportiva y más."
    },
    {
        title: "Cafetería",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FCAFETER%C3%8DA_360.jpg?alt=media",
        description: "Tu alimentación es importante. Date el impulso que necesitas con una carta variada y nutritiva a tu disposición en cada sede b2. La mejor manera de recargar antes y después de tu entrenamiento."
    },
    {
        title: "Spa",
        imgsrc: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FSPA-360.jpg?alt=media",
        description: "Haz una pausa y regálate momentos de autocuidado. El servicio de spa el complemento perfecto para tu compromiso con el bienestar. Déjate llevar y renueva cuerpo y mente en un ambiente de serenidad total."
    }
]